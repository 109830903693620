import React from "react"
import { Container } from "./style"
import { H2Title, Section, GrayBorder } from "../../../globalStyle"
import ListItem from "./ListItem"
import IListItem from "./types"
import Pager from "../../UI/Pager"

const ListItemTemplate = (props: IListItem) => {
  const { productsData, title, context, prefixUrl } = props
  return (
    <Section style={{ marginTop: "100px" }}>
      <Container>
        <H2Title marginLeft="15px">{title}</H2Title>
      </Container>
      <GrayBorder />
      <Container>
        {productsData.map((p, idx) => (
          <ListItem key={idx} listItemData={p} prefixUrl={prefixUrl} />
        ))}
        <Pager
          slug={prefixUrl}
          context={context}
          margin={"20px 15px 50px 15px"}
        />
      </Container>
    </Section>
  )
}

export default ListItemTemplate
