import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  ImageWrapper,
  ContentWrapper,
  H3BlogTitle,
  ReadMoreWrapper,
  TextWrapper,
} from "./style"
import {
  P,
  OrangeSpan,
  ReadMoreLink,
  ButtonIcon,
  IconWrapper,
  TagsBorder,
} from "../../../../globalStyle"
import arrowRight from "@iconify-icons/bi/arrow-right"
import IListItem from "./types"
import { Link } from "gatsby"

const ListItem = (props: IListItem) => {
  const { listItemData, prefixUrl } = props
  const extension = listItemData.node.picture.file.fileName.slice(
    listItemData.node.picture.file.fileName.length - 3,
    listItemData.node.picture.file.fileName.length
  )
  return (
    <Link to={`/${prefixUrl}/` + listItemData.node.slug}>
      <ContentWrapper>
        <ImageWrapper>
          {extension === "svg" ? (
            <img src={listItemData.node.picture.file.url} />
          ) : (
            <GatsbyImage
              imgStyle={{ objectFit: "contain" }}
              image={listItemData.node.picture.gatsbyImageData}
              alt={listItemData.node.title}
            />
          )}
        </ImageWrapper>
        <TextWrapper>
          <H3BlogTitle isDarkBlue={true}>{listItemData.node.title}</H3BlogTitle>
          {prefixUrl !== "products" && (
            <div>
              <OrangeSpan>{listItemData.node.tags}</OrangeSpan>
              <TagsBorder />
            </div>
          )}
          <P lineHeight="26px">
            {listItemData.node.shortDescription.shortDescription}
          </P>
          <ReadMoreWrapper>
            <ReadMoreLink>
              Read More
              <IconWrapper>
                <ButtonIcon icon={arrowRight}></ButtonIcon>
              </IconWrapper>
            </ReadMoreLink>
          </ReadMoreWrapper>
        </TextWrapper>
      </ContentWrapper>
    </Link>
  )
}

export default ListItem
