import React from "react"
import IPager from "./types"
import PaginationButton from "./PaginationButton"
import { FlexCenter } from "./styles"

const Pager = ({ context, margin, slug }: IPager) => {
  let pageList = [{ id: 1, slug: "/" + slug }]
  const generateSlugs = () => {
    for (var i = 0; i < context.numberOfPages; i++) {
      if (i != 0) pageList[i] = { id: i + 1, slug: "/" + slug + "/" + (i + 1) }
    }
  }

  generateSlugs()
  return (
    <div style={margin && { margin: margin }}>
      <FlexCenter>
        {context.humanPageNumber != 1 && (
          <PaginationButton type="first" slug={"/" + slug + "/"} />
        )}
        {context.previousPagePath != "" && (
          <PaginationButton type="previous" slug={context.previousPagePath} />
        )}
        {pageList.map((b, idx) =>
          b.id === context.humanPageNumber ? (
            <PaginationButton key={idx} type="current" slug={"#"} id={b.id} />
          ) : (
            <PaginationButton
              key={idx}
              type="normal"
              slug={b.id !== 1 ? "/" + slug + "/" + b.id : "/" + slug + "/"}
              id={b.id}
            />
          )
        )}
        {context.nextPagePath != "" && (
          <PaginationButton type="next" slug={context.nextPagePath} />
        )}
        {context.humanPageNumber != context.numberOfPages && (
          <PaginationButton
            type="last"
            slug={"/" + slug + "/" + context.numberOfPages}
          />
        )}
      </FlexCenter>
    </div>
  )
}

export default Pager
