import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1470px;

  @media (max-width: 1470px) {
    width: 100%;
  }
`
