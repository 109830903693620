import styled from "styled-components"
import { H3Title } from "../../../../globalStyle"

export const ImageWrapper = styled.div`
  margin-right: 15px;
  min-width: 50%;
  & > div,
  img {
    width: 100%;
    height: calc(100% - 10px);
  }

  @media (max-width: 650px) {
    margin: 0 0 15px 0;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 980px) {
    margin-bottom: 10px;
    width: 100%;
    & > div {
      width: 100%;
    }
  }
`
export const H3BlogTitle = styled(H3Title)`
  font-size: ${props => props.theme.fontSizes.medium};
  transition: 0.3s;
`

export const TextWrapper = styled.div`
  margin: 0 10px 0 10px;
  @media (max-width: 980px) {
    margin: 0 10px 0 0;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 15px 15px 30px 15px;
  height: 390px;
  &:hover {
    & > div > h3 {
      color: ${props => props.theme.colors.orange};
    }
  }
  @media (max-width: 980px) {
    flex-direction: column;
    height: 100%;
  }
`
export const ListItemWrapper = styled.div``

export const ReadMoreWrapper = styled.div``
