import React from "react"
import IPaginationButton from "./types"
import {
  ButtonClickable,
  ButtonLink,
  ButtonWrapper,
  BtnBlackSpan,
} from "./styles"

const PaginationButton = ({ margin, slug, type, id }: IPaginationButton) => {
  return (
    <ButtonWrapper margin={margin && margin}>
      <ButtonLink to={slug} type={type && type}>
        <ButtonClickable type={type}>
          {type === "first" && <BtnBlackSpan>{"|<"}</BtnBlackSpan>}
          {type === "previous" && <BtnBlackSpan>{"<"}</BtnBlackSpan>}
          {type === "normal" && <BtnBlackSpan>{id}</BtnBlackSpan>}
          {type === "current" && <BtnBlackSpan>{id}</BtnBlackSpan>}
          {type === "next" && <BtnBlackSpan>{">"}</BtnBlackSpan>}
          {type === "last" && <BtnBlackSpan>{">|"}</BtnBlackSpan>}
        </ButtonClickable>
      </ButtonLink>
    </ButtonWrapper>
  )
}

export default PaginationButton
