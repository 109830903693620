import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import ListItemComponent from "../components/Sections/ListItemTemplate"
import { graphql } from "gatsby"

export const blog = graphql`
  query ListItem($skip: Int!, $limit: Int!) {
    allContentfulProduct(
      skip: $skip
      limit: $limit
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          tags
          slug
          shortDescription {
            shortDescription
          }
          picture {
            gatsbyImageData(quality: 100)
            file {
              fileName
              url
            }
          }
          title
        }
      }
    }
    allContentfulBlogPost(
      skip: $skip
      limit: $limit
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          picture {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            title
            file {
              fileName
              url
            }
          }
          shortDescription {
            shortDescription
          }
          title
          slug
          tags
        }
      }
    }
    allContentfulCaseStudy(
      skip: $skip
      limit: $limit
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          picture {
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            file {
              fileName
              url
            }
          }
          shortDescription {
            shortDescription
          }
          slug
          title
          tags
        }
      }
    }
    allContentfulMeetup(
      skip: $skip
      limit: $limit
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          picture {
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 100, width: 400)
            file {
              fileName
              url
            }
          }
          shortDescription {
            shortDescription
          }
          slug
          title
          tags
        }
      }
    }
  }
`
const ListItem = props => {
  let items
  if (props.pageContext.queryType === "Product") {
    items = props.data.allContentfulProduct.edges
  }
  if (props.pageContext.queryType === "CaseStudy") {
    items = props.data.allContentfulCaseStudy.edges
  }
  if (props.pageContext.queryType === "BlogPost") {
    items = props.data.allContentfulBlogPost.edges
  }
  if (props.pageContext.queryType === "Meetups") {
    items = props.data.allContentfulMeetup.edges
  }

  return (
    <Layout>
      <Head
        description={props.pageContext.metaDescription}
        pageTitle={props.pageContext.pageTitle}
        keywords={props.pageContext.keywords}
      />
      <ListItemComponent
        context={props.pageContext}
        productsData={items}
        title={props.pageContext.title}
        prefixUrl={props.pageContext.prefixUrl}
      />
    </Layout>
  )
}

export default ListItem
